@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "fonts/slickslider/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @return url('img/slickslider/'+$url);
}

@function slick-font-url($url) {
    @return url('fonts/slickslider/'+$url);
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}




.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 35px;
    width: 21px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 85px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 10000;

    &:hover /*, &:focus */ {
      filter: brightness(80%);
      /*  outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        } */
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: FontAwesome;
        font-size: 60px ;
        line-height: 1;
        color: #fff;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.smaller-arrows .slick-prev,
.smaller-arrows .slick-next{
top: calc(50% - 10px);
}

[dir="rtl"] .smaller-arrows .slick-next{
  left:35px;
}
[dir="rtl"] .smaller-arrows .slick-left{
  right:35px;
}

.smaller-arrows .slick-prev:before,
.smaller-arrows .slick-next:before{
  font-size: 45px !important;
}

.slick-prev {

/*  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17.5px 0 17.5px 18px;
  border-color: transparent transparent transparent #9f9f9f;*/

  width: 26px;
  height: 46px;
  background: url(../images/home_arrow_05.png);

    left: 50px;
    [dir="rtl"] & {
        left: auto;
        right: -43px;
    }

  /*  &:after{
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15.5px 0 15.5px 16px;
      border-color: transparent transparent transparent #d1d1d1;
      position: absolute;
      left: -18px;
      top: -15px;
    }*/
/*    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }*/
}

.slick-next {

/*  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17.5px 18px 17.5px 0;
  border-color: transparent #9f9f9f transparent transparent; */

  width: 26px;
  height: 46px;
  background: url(../images/home_arrow_03.png);

    right: 50px;
    [dir="rtl"] & {
        left: -24px;
        right: auto;
    }

/*    &:after{
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15.5px 16px 15.5px 0;
      border-color: transparent #d1d1d1 transparent transparent;
      position: absolute;
      left: 2px;
      top: -15px;
    }*/
}

/* Dots */

.slick-dotted.slick-slider {
    //margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    //bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 13px;
        width: 13px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 13px;
            width: 13px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            border:1px solid #a1a1a1;
            background-color:#fff;
            &:hover, &:focus {
                outline: none;
                background-color:#d1d1d1;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
        }
        &.slick-active button {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
            background-color:#d1d1d1;
        }
    }
}
